import { BayanNgGubat } from "./SorsogonMunicipalities/BayanNgGubat";
import { Matnog } from "./SorsogonMunicipalities/Matnog";
import { Casiguran } from "./SorsogonMunicipalities/Casiguran";

export const SorsogonDetails = [
  {
    id: 1,
    title: "Barcelona",
    alt: "Barcelona",
    url: "/barcelona",
    municipalityList: [],
  },
  {
    id: 2,
    title: "Bulan",
    alt: "Bulan",
    url: "/bulan",
    municipalityList: [],
  },
  {
    id: 3,
    title: "Bulusan",
    alt: "Bulusan",
    url: "/bulusan",
    municipalityList: [],
  },
  {
    id: 4,
    title: "Casiguran",
    complete: "true",
    alt: "Casiguran",
    url: "/casiguran",
    municipalityList: [Casiguran],
  },
  {
    id: 5,
    title: "Castilla",
    alt: "Castilla",
    url: "/castilla",
    municipalityList: [],
  },
  {
    id: 6,
    title: "Donsol",
    alt: "Donsol",
    url: "/donsol",
    municipalityList: [],
  },
  {
    id: 7,
    title: "Bayan ng Gubat",
    complete: "true",
    alt: "Bayan ng Gubat",
    url: "/bayannggubat",
    municipalityList: [BayanNgGubat],
  },
  {
    id: 8,
    title: "Irosin",
    alt: "Irosin",
    url: "/irosin",
    municipalityList: [],
  },
  {
    id: 9,
    title: "Juban",
    alt: "Juban",
    url: "/juban",
    municipalityList: [],
  },
  {
    id: 10,
    title: "Magallanes",
    alt: "Magallanes",
    url: "/magallanes",
    municipalityList: [],
  },
  {
    id: 11,
    title: "Matnog",
    complete: "true",
    alt: "Matnog",
    url: "/matnog",
    municipalityList: [Matnog],
  },
  {
    id: 12,
    title: "Pilar",
    alt: "Pilar",
    url: "/pilar",
    municipalityList: [],
  },
  {
    id: 13,
    title: "Prieto Diaz",
    alt: "Prieto Diaz",
    url: "/prietodiaz",
    municipalityList: [],
  },
  {
    id: 14,
    title: "Santa Magdalena",
    alt: "Santa Magdalena",
    url: "/santamagdalena",
    municipalityList: [],
  },
  {
    id: 15,
    title: "Sorsogon City",
    alt: "Sorsogon City",
    url: "/sorsogoncity",
    municipalityList: [],
  },
];
